import styled from "styled-components";

/**
 * The Block wrapper
 */
export const BoxLogin = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    gap: 16px;

    position: absolute;
    width: 420px;
    height: 258px;
    left: 210px;
    top: 351px;

    /* Grit Blue (15% Opacity) */

    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 8px;
`;

/**
 * The Login Wrapper
 */
export const LoginWrapper = styled.div`
    /* Login */
    width: 51px;
    height: 23px;

    /* Heading 3 */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height, or 115% */
    letter-spacing: -0.04em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const BackgroundWrapper = styled.img`
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    position: absolute;
    width: 840px;
    height: 820px;
    left: 840px;
    top: 80px;

    background: #e6f2fe;
`;

export const InputWrapper = styled.input`
    /* Auto layout */
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    /* position: absolute; */
    width: 372px;
    height: 53px;

    /* Grit Blue (15% Opacity) */
    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 4px;
    margin-bottom: 4px;
`;

export const LoginButton = styled.button`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;

    width: 196.17px;
    height: 33px;

    /* Bay Blue */
    background: #c3e0fd;
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-top: 10px;
    margin-left: 2px;
`;
