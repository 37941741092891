import styled from "styled-components";

export const ImgMarkedWrapper = styled.img`
    width: 18px;
    height: 18px;
`;

/**
 * The Upload Modal Wrapper Button
 */
export const UploadModalWrapper = styled.button`
    display: flex;
    width: 200px;
    height: 60.117px;
    padding: 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--bay-blue, #c3e0fd);
    border-color: transparent;
`;

export const TitleWrapper = styled.div`
    color: var(--indigo-dye, #164069);

    /* Heading 3 */
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 115% */
    letter-spacing: -0.8px;
`;

export const SubtitleWrapper = styled.div`
    color: var(--indigo-dye, #164069);

    /* Body */
    margin-top: 10px;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 131.25% */
    letter-spacing: -0.48px;
    margin-bottom: 16px;
`;

export const ContainerWrapper = styled.button`
    display: flex;
    height: 110px;
    padding: 10px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    width: 448px;
    height: 55px;

    border-radius: 10px;
    border: 1px solid var(--grit-blue-15-opacity, rgba(1, 13, 25, 0.15));
    background: var(--alice-blue, #e6f2fe);
    margin-bottom: 16px;
`;

export const UnselectedWrapper = styled.button`
    display: flex;
    height: 110px;
    padding: 10px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    width: 448px;
    height: 55px;
    border-radius: 4px;
    border: 1px solid var(--grit-blue-15-opacity, rgba(1, 13, 25, 0.15));
    background: white;
    margin-bottom: 16px;
`;

export const ContainerTitle = styled.div`
    color: var(--grit-blue, #010d19);

    /* Body */
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 131.25% */
    letter-spacing: -0.48px;
    text-align: left;

    width: 100%;
    display: block;
`;

export const ContainerSubtitle = styled.div`
    color: var(--indigo-dye, #164069);

    /* Detail */
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.36px;
    text-align: left;

    width: 100%;
    display: block;
`;

export const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
