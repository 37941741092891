import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';

import { Footer } from '../Footer';
import { StateVideo } from '../types';
import { VideoDescriptionThumbnail } from '../VideoDescriptionThumbnail';
import { ImageUploader } from './ImageUploader';
import { UPLOAD_THUMBNAIL } from './queries';
import { InputWrapper, TextAreaWrapper, TopContainer } from './wrappers';

interface DetailProps {
  setState: (state: StateVideo) => void;
  state: StateVideo;
}

type Inputs = {
  title: string;
  description: string;
};

interface Size {
  width: number;
  height: number;
}

export function Details({ setState, state }: DetailProps) {
  const [uploadThumbnail, { data: uploadData }] = useMutation(UPLOAD_THUMBNAIL);
  const [pendingThumbnail, setPendingThumbnail] = useState<File | null>(null);

  const { register, watch } = useForm<Inputs>({
    defaultValues: {
      title: state.title,
      description: state.description
    }
  });

  useEffect(() => {
    if (uploadData) {
      const { uploadThumbnail: thumbnailResponse } = uploadData;
      setState({
        index: state.index,
        title: state.title,
        description: state.description,
        videoUrl: state.videoUrl,
        thumbnailUrl: thumbnailResponse.url,
        videoId: state.videoId,
        audience: state.audience,
        emailPreference: state.emailPreference,
        progress: state.progress
      });
    }
  }, [uploadData, setState]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "title") {
        setState({
          index: state.index,
          title: value[name] ?? "",
          description: state.description,
          videoUrl: state?.videoUrl,
          thumbnailUrl: state?.thumbnailUrl,
          videoId: state?.videoId,
          audience: state?.audience,
          emailPreference: state?.emailPreference,
          progress: state?.progress
        });
      }
      if (name === "description") {
        setState({
          index: state.index,
          title: state.title,
          description: value[name] ?? "",
          videoUrl: state?.videoUrl,
          thumbnailUrl: state?.thumbnailUrl,
          videoId: state?.videoId,
          audience: state?.audience,
          emailPreference: state?.emailPreference,
          progress: state?.progress
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setState]);

  useEffect(() => {
    if (state.videoId && pendingThumbnail) {
      uploadThumbnail({
        variables: {
          file: pendingThumbnail,
          videoId: state.videoId
        }
      });
      setPendingThumbnail(null);
    }
  }, [state.videoId, pendingThumbnail, uploadThumbnail]);

  const onNext = () => {
    const updatedTitle = watch("title");
    const updatedDescription = watch("description");
    setState({
      index: 2,
      title: updatedTitle || state?.title,
      description: updatedDescription || state?.description,
      videoUrl: state?.videoUrl,
      thumbnailUrl: state?.thumbnailUrl,
      videoId: state?.videoId,
      audience: state.audience,
      emailPreference: state?.emailPreference,
      progress: state?.progress
    });
  };

  const indexState = {
    index: 0,
    title: state?.title,
    description: state?.description,
    videoUrl: state?.videoUrl,
    thumbnailUrl: state?.thumbnailUrl,
    videoId: state?.videoId,
    audience: state.audience,
    emailPreference: state?.emailPreference,
    progress: state?.progress
  };

  const uploadFiles = (files: FileList | null) => {
    if (!files || !files.item(0)) return;
    const file = files.item(0)!;

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      alert("Please upload an image file");
      return;
    }

    // Check image dimensions
    const img = new Image();
    img.onload = (event: Event) => {
      const target = event.target as HTMLImageElement;
      const { width, height } = target;

      const validSizes: Size[] = [
        { width: 1280, height: 720 },
        { width: 2560, height: 1440 },
        { width: 3840, height: 2160 }
      ];

      const isValidSize: boolean = validSizes.some(
        (size: Size): boolean => size.width === width && size.height === height
      );

      if (!isValidSize) {
        alert(
          "Please upload an image that is exactly 1280×720, 2560×1440, or 3840×2160 pixels"
        );
        return;
      }

      // If we've passed the check, proceed with the upload
      if (state.videoId) {
        uploadThumbnail({
          variables: {
            file,
            videoId: state.videoId
          }
        });
      } else {
        setPendingThumbnail(file);
      }
    };

    img.onerror = () => {
      alert("Error loading image. Please try again.");
    };
    img.src = URL.createObjectURL(file);
  };

  return (
    <div>
      <Modal.Header>
        <Modal.Title>{state.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TopContainer>
          <form method="POST" action="login">
            <InputWrapper
              placeholder="Title"
              {...register("title", { required: true })}
            />
            <TextAreaWrapper
              placeholder="Description"
              {...register("description")}
            />
            <ImageUploader
              state={state}
              setState={setState}
              uploadFiles={uploadFiles}
            />
          </form>
          <VideoDescriptionThumbnail state={state} />
        </TopContainer>
      </Modal.Body>
      <Modal.Footer>
        <Footer state={state} onNext={onNext} setState={setState} />
      </Modal.Footer>
    </div>
  );
}
