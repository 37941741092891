// local
import {
    BackgroundWrapper,
    HeaderWrapper,
    PaymentWrapper,
    SignUpButtonWrapper,
    SubtitleWrapper,
    TitleWrapper
} from "./wrappers";

// libraries
import React from "react";

export function FourthComponent() {
    return (
        <>
            <BackgroundWrapper></BackgroundWrapper>
            <PaymentWrapper src="/payment.png"></PaymentWrapper>
            <HeaderWrapper>The Idea</HeaderWrapper>
            <TitleWrapper>Earn money creating what you love.</TitleWrapper>
            <SubtitleWrapper>
                Build your audience on Netbox, or bring them over. When you're
                ready, you can add paid subscriptions. <br />
                <br />
                We take care of the details so you can focus on creating.
            </SubtitleWrapper>
            <SignUpButtonWrapper>Learn more</SignUpButtonWrapper>
        </>
    );
}
