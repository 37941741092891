// local
import {
    BuildWrapper,
    PlaceWrapper,
    PrincipleWrapper,
    SubscriptionImgWrapper
} from "./wrappers";

// libraries
import React from "react";

export function PlaceComponent() {
    return (
        <>
            <PrincipleWrapper>The Principle</PrincipleWrapper>
            <PlaceWrapper>
                A place for independent creators who want to own their audience
            </PlaceWrapper>
            <BuildWrapper>
                <div>
                    Build a community that you own that your fans will love and
                    create a subscription plan to make money that makes it worth
                    it.
                </div>
                <br />
                <div>
                    Publishing is free, with no limits. We only make money when
                    you do.
                </div>
            </BuildWrapper>
            <SubscriptionImgWrapper src="/subscription.png"></SubscriptionImgWrapper>
        </>
    );
}
