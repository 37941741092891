import styled from "styled-components";

/**
 * The Uploads Button Wrapper
 */
export const UploadButtonWrapper = styled.button`
    position: absolute;
    width: 195px;
    height: 43px;
    left: 1103px;
    top: 20px;

    background: #ffffff;
    border: 1px solid #469df8;
    box-sizing: border-box;
    border-radius: 8px;

    /* Upload */
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #469df8;
`;
