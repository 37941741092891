import "react-toastify/dist/ReactToastify.css";

// external
import React, { useEffect, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { gql, useMutation } from "@apollo/client";

// import { setUser } from '../Auth/slice';
// local
import { Header } from "../components/Header";
import {
    BackgroundWrapper,
    BoxLogin,
    InputWrapper,
    LoginButton,
    LoginWrapper
} from "./wrappers";

interface SignInFormProps {
    onSignIn: (email: string, password: string) => void;
}

const SIGN_IN = gql`
    mutation ($email: String!, $password: String!) {
        signIn(email: $email, password: $password) {
            token
            expiresIn
            authState
            refreshToken
            refreshTokenExpireIn
        }
    }
`;

type Inputs = {
    email: string;
    password: string;
};

export const Login: React.FC = () => {
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const { email, password } = data;
        const id = toast.loading("Logging in...");
        mutateFunction({
            variables: {
                email,
                password
            }
        }).then((response) => {
            if (response.data.signIn) {
                if (
                    response.data.signIn.token &&
                    signIn({
                        token: response.data.signIn.token,
                        expiresIn: 1800,
                        tokenType: "Bearer",
                        authState: response.data.signIn.authUserState
                        // refreshToken: response.data.signIn.refreshToken, // Only if you are using refreshToken feature
                        // refreshTokenExpireIn: 1200, // Only if you are using refreshToken feature
                    })
                ) {
                    toast.update(id, {
                        render: "Success",
                        type: "success",
                        isLoading: true
                    });
                    setUserInfo(
                        Object.assign({}, userInfo, {
                            email: email,
                            password: password
                        })
                    );
                    // Only if you are using refreshToken feature
                    // Redirect or do-something
                } else {
                    //Throw error
                    toast.update(id, {
                        render: "Wrong email or password",
                        type: "error",
                        isLoading: false,
                        autoClose: 3000
                    });
                }
            }
        });
    };
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm<Inputs>();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [mutateFunction, { data }] = useMutation(SIGN_IN);
    const signIn = useSignIn();
    const [userInfo, setUserInfo] = useState<{
        /** User email */
        email?: string;
        /** Password */
        password?: string;
    }>({});

    useEffect(() => {
        if (userInfo?.email && userInfo?.password) {
            // dispatch(
            //     setUser({
            //         email: userInfo.email,
            //         password: userInfo.password
            //     })
            // );
            navigate("/");
        }
    }, [dispatch, userInfo]);

    let reactInner = (
        <div>
            <BoxLogin>
                <LoginWrapper>Login</LoginWrapper>
                <form
                    method="POST"
                    action="login"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputWrapper
                        type="email"
                        placeholder="Email"
                        {...register("email", { required: true })}
                    />
                    <InputWrapper
                        type="password"
                        placeholder="Password"
                        id="password"
                        {...register("password", { required: true })}
                    />
                    {errors.password && <span>This field is required</span>}
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <LoginButton type="submit">Login</LoginButton>
                </form>
            </BoxLogin>
            <BackgroundWrapper src="loginBackground.png" />
        </div>
    );
    if (userInfo.email && userInfo.password) {
        reactInner = (
            <div>
                <h1>Logged in</h1>
                <p>Email: {userInfo.email}</p>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Header />
            {reactInner}
        </React.Fragment>
    );
};
