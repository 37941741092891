import React, { useState } from "react";

import { UpVoteWrapper } from "./wrappers";

export const UpVoteButton: React.FC<{}> = ({}) => {
    const [clicked, setClicked] = useState(false);
    return (
        <UpVoteWrapper
            upvote={clicked}
            onClick={() => {
                setClicked(!clicked);
            }}
        >
            <img src={clicked ? "/upvotedUp.png" : "/upvote.png"} />
            {"Upvote"}
        </UpVoteWrapper>
    );
};
