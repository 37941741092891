// external
import styled from "styled-components";

export const SubscribeButtonWrapper = styled.button`
    position: absolute;
    width: 234px;
    height: 54px;
    left: 1072px;
    top: 140px;

    border: none;
    background: #469df8;
    border-radius: 6px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #ffffff;
`;

export const UnsubscribeButtonWrapper = styled.button`
    position: absolute;
    width: 234px;
    height: 54px;
    left: 1072px;
    top: 140px;

    border: none;
    background: #c3e0fd;
    border-radius: 6px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #469df8;
`;
