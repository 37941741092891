import { gql } from '@apollo/client';

export const GET_VIDEO_INFO = gql`
    query VIDEO($video_id: String!) {
        video(video_id: $video_id) {
            thumbnailUrl
            videoUrl
            title
            description
            upVotes
            downVotes
            createdAt
            id
            channel_id
        }
    }
`;
