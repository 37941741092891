import "./ImageUploader.css"; // Import the CSS file

// external
import React, { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FileDrop } from "react-file-drop";
import { useForm } from "react-hook-form";

import { StateVideo } from "../../types";
import { imageMessages } from "./messages";
import {
    FileDropWrapper,
    TitleUploadWrapper,
    UploadImageWrapper
} from "./wrappers";

interface UploadProps {
    /** The current state of the modal */
    state: StateVideo;
    /** The state of modal */
    setState: (state: StateVideo) => void;
    /** Handle drop */
    uploadFiles: (files: FileList) => void;
    /** Set failure of upload */
    setFailure?: (failure: boolean) => void;
}

export function ImageUploader({ setState, state, uploadFiles }: UploadProps) {
    window.addEventListener(
        "dragover",
        function (e) {
            e = e || event;
            e.preventDefault();
        },
        false
    );

    window.addEventListener(
        "drop",
        function (e) {
            e = e || event;
            e.preventDefault();
        },
        false
    );
    const handleDrop = async (files: FileList | null) => {
        if (!files || !files.item(0)) return;
        const file = files.item(0)!;
        uploadFiles(files);
    };

    const handleClick = useCallback(() => {
        const input = document.createElement("input");
        input.type = "file";
        input.multiple = true;
        input.onchange = (event) => {
            const files = (event.target as HTMLInputElement).files;
            if (files) handleDrop(files);
        };
        input.click();
    }, []);

    return (
        <>
            <FileDropWrapper>
                <FileDrop
                    className="drop"
                    onDrop={handleDrop}
                    onTargetClick={handleClick}
                >
                    <UploadImageWrapper src="uploader.png"></UploadImageWrapper>
                    <TitleUploadWrapper>
                        {imageMessages.uploadThumbnail}
                    </TitleUploadWrapper>
                </FileDrop>
            </FileDropWrapper>
            <div style={{ height: "60px" }}></div>
        </>
    );
}
