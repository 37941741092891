// external
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Footer } from '../Footer';
import { StateVideo } from '../types';
import { VideoDescriptionThumbnail } from '../VideoDescriptionThumbnail';
import { reviewMessages } from './messages';
import {
    ContainerSubtitle, ContainerTitle, ContainerWrapper, ImgMarkedWrapper, SubtitleWrapper,
    TitleWrapper, TopContainer, UnselectedWrapper
} from './wrappers';

type EmailPreference = "all" | "paid";

interface ContainerProps {
    title: string;
    subtitle: string;
    selected: boolean;
    preference: EmailPreference;
    setEmailPreference: (preference: EmailPreference) => void;
}

const ContainerBox = ({
    title,
    subtitle,
    selected,
    preference,
    setEmailPreference
}: ContainerProps) => {
    const imageSrc = selected ? "/marked.png" : "/unmarked.png";
    const Wrapper = selected ? ContainerWrapper : UnselectedWrapper;

    return (
        <Wrapper onClick={() => setEmailPreference(preference)}>
            <ImgMarkedWrapper src={imageSrc} />
            <div>
                <ContainerTitle>{title}</ContainerTitle>
                <ContainerSubtitle>{subtitle}</ContainerSubtitle>
            </div>
        </Wrapper>
    );
};

interface ReviewProps {
    setState: (state: StateVideo) => void;
    state: StateVideo;
    onDone: () => void;
}

export function Review({ setState, state, onDone }: ReviewProps) {
    const title = state.title;
    const [show, setShow] = useState(false);
    const initialEmailPreference: EmailPreference =
        state.emailPreference === "paid" ? "paid" : "all";

    const [emailPreference, setEmailPreference] = useState<EmailPreference>(
        initialEmailPreference
    );

    useEffect(() => {
        setState({
            index: state.index,
            title,
            description: state?.description,
            videoUrl: state?.videoUrl,
            thumbnailUrl: state?.thumbnailUrl,
            videoId: state?.videoId,
            audience: state.audience,
            emailPreference,
            progress: state?.progress
        });
    }, [emailPreference]);

    const handleEmailPreferenceChange = (newPreference: EmailPreference) => {
        setEmailPreference(newPreference);
    };

    const indexState = {
        index: 3,
        emailPreference,
        title,
        description: state?.description,
        videoUrl: state?.videoUrl,
        thumbnailUrl: state?.thumbnailUrl,
        videoId: state?.videoId,
        audience: state.audience,
        progress: state?.progress
    };
    return (
        <div>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TopContainer>
                    <div>
                        <TitleWrapper>{reviewMessages.review}</TitleWrapper>
                        <SubtitleWrapper>
                            {reviewMessages.subtitle}
                        </SubtitleWrapper>
                        <ContainerBox
                            title={reviewMessages.sendToAll}
                            subtitle={reviewMessages.sendToAllSubtitle}
                            selected={emailPreference === "all"}
                            preference="all"
                            setEmailPreference={handleEmailPreferenceChange}
                        />
                        <ContainerBox
                            title={reviewMessages.sendToPaid}
                            subtitle={reviewMessages.sendToPaidSubtitle}
                            selected={emailPreference === "paid"}
                            preference="paid"
                            setEmailPreference={handleEmailPreferenceChange}
                        />
                    </div>
                    <VideoDescriptionThumbnail state={state} />
                </TopContainer>
            </Modal.Body>
            <Modal.Footer>
                <Footer
                    state={indexState}
                    onNext={onDone}
                    setState={setState}
                />
            </Modal.Footer>
        </div>
    );
}
