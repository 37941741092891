import styled from "styled-components";

/**
 * The Search wrapper
 */
export const SearchWrapper = styled.input`
    box-sizing: border-box;

    /* Auto layout */
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 26px;
    gap: 10px;

    width: 500px;
    height: 43px;
    /* Grit Blue (15% Opacity) */

    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 10px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
`;
