// external
import React from "react";
import { useParams } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";

// local
import { Header } from "../components/Header";
import { SubscriberView } from "./SubscriberView";
import { VideoThumbnail } from "./VideoThumbnail";
import { UploadsTitleWrapper, VideoGripWrapper } from "./wrappers";

const CHANNELS = gql`
    query GET_CHANNELS {
        channels {
            imgUrl
            title
            description
            username
            id
        }
    }
`;

const GET_VIDEOS = gql`
    query VIDEOS($channel_id: Int!) {
        videos(channel_id: $channel_id) {
            thumbnailUrl
            videoUrl
            title
            upVotes
            downVotes
            createdAt
            id
        }
    }
`;

export function User() {
    const { data, loading } = useQuery(CHANNELS);
    const newData = data?.channels ?? [];
    let { username } = useParams();

    const filteredData = newData.filter(
        (row: any) =>
            username &&
            (row.username.toLowerCase().includes(username.toLowerCase()) ||
                row.title.toLowerCase().includes(username.toLowerCase()))
    );
    const channel = filteredData[0];

    const videoResults = useQuery(GET_VIDEOS, {
        variables: { channel_id: parseInt(channel?.id) }
    });

    const videoData = videoResults?.data?.videos ?? [];
    if (loading) {
        return <div></div>;
    }
    if (!channel) {
        return <div>Channel not found</div>;
    }
    const { title, description, imgUrl, id } = channel;

    return (
        <>
            <Header />
            <SubscriberView
                description={description}
                imgUrl={imgUrl}
                title={title}
                id={id}
            ></SubscriberView>
            <UploadsTitleWrapper>Uploads</UploadsTitleWrapper>
            <VideoGripWrapper>
                {videoData.length > 0 ? (
                    videoData.map((video: any) => (
                        <VideoThumbnail
                            key={video.id}
                            url={video.thumbnailUrl}
                            title={video.title}
                            createdAt={video.createdAt}
                            videoId={video.id}
                        ></VideoThumbnail>
                    ))
                ) : (
                    <></>
                )}
            </VideoGripWrapper>
        </>
    );
}
