import styled from "styled-components";

export const BoxWrapper = styled.button.attrs(
    (props: { x: string; width: string }) => props
)`
    position: absolute;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid var(--grit-blue-15-opacity, rgba(1, 13, 25, 0.15));
    border-color: transparent;
    background: var(--bay-blue, #c3e0fd);
    left: ${(props) => props.x};

    width: ${(props) => props.width};
    height: 39px;
    color: var(--indigo-dye, #164069);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px; /* 105.556% */
    letter-spacing: -0.9px;
`;

export const InactiveBoxWrapper = styled.button.attrs(
    (props: { x: string }) => props
)`
    position: absolute;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border-color: transparent;
    background: var(--white, #fff);
    left: ${(props) => props.x};

    width: 77px;
    height: 39px;
    color: var(--indigo-dye, #164069);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px; /* 105.556% */
    letter-spacing: -0.9px;
`;
