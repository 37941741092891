// external
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { gql, useMutation } from "@apollo/client";

import { selectUserValue } from "../../Auth/selectors";
import { subscriberViewMessages } from "./messages";
import { SubscribeButtonWrapper, UnsubscribeButtonWrapper } from "./wrappers";

const SUBSCRIBE = gql`
    mutation SUBSCRIBE($userId: Int!, $channel: Int!) {
        subscribe(userId: $userId, channel: $channel)
    }
`;

const UNSUBSCRIBE = gql`
    mutation UNSUBSCRIBE($userId: Int!, $channel: Int!) {
        unsubscribe(userId: $userId, channel: $channel)
    }
`;

/**
 * The Subscribe Button Props theme
 */
export interface SubscribeButtonProps {
    /** Is the user subscribed */
    subscribed?: boolean;
    /** The channel number */
    channel: number;
}

export const SubscribeButton: React.FC<SubscribeButtonProps> = (props) => {
    const userValue = useSelector(selectUserValue);
    const [subscribedState, setSubscribedState] = useState(props.subscribed);

    const [subscribeFunction, { data }] = useMutation(SUBSCRIBE);
    const [unsubscribeFunction, { data: unsubscribeData }] =
        useMutation(UNSUBSCRIBE);

    useEffect(() => {
        setSubscribedState(props.subscribed);
    }, [props.subscribed]);

    const handleSubscribe = () => {
        subscribeFunction({
            variables: {
                userId: parseInt(userValue.id || "0"),
                channel: props.channel
            }
        }).then(() => {
            setSubscribedState(true);
        });
    };

    const handleUnsubscribe = () => {
        unsubscribeFunction({
            variables: {
                userId: parseInt(userValue.id || "0"),
                channel: props.channel
            }
        }).then(() => {
            setSubscribedState(false);
        });
    };

    if (subscribedState === undefined) {
        return <></>;
    }
    if (subscribedState) {
        return (
            <UnsubscribeButtonWrapper onClick={handleUnsubscribe}>
                {subscriberViewMessages.unsubscribe}
            </UnsubscribeButtonWrapper>
        );
    }
    return (
        <SubscribeButtonWrapper onClick={handleSubscribe}>
            {subscriberViewMessages.subscribe}
        </SubscribeButtonWrapper>
    );
};
