import styled from "styled-components";

/**
 * The Video Image Wrapper
 */
export const VideoImgWrapper = styled.img`
    width: 348px;
    height: 196px;
    object-fit: contain;
`;

/**
 * The Title Wrapper
 */
export const TitleWrapper = styled.div`
    position: relative;
    width: 195px;
    height: 100px;
    /* This solves the problem when the margin goes all the way to the right */
    float: left;
    top: 4px;
    left: 4px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: left;

    color: #000000;
`;

/**
 * The Subtitle Wrapper
 */
export const SubtitleWrapper = styled.div`
    position: relative;
    width: 95px;
    height: 21px;
    right: 10px;
    float: right;
    top: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #000000;
`;

/**
 * The Preview Wrapper
 */
export const PreviewWrapper = styled.div`
    width: 360px;
    height: 260px;
    margin-right: 40px;
    /* Fix when the margin goes all the right */
    display: inline-block;
`;

/**
 * The Button wrapper
 */
export const ButtonWrapper = styled.button`
    background-color: transparent;
    border-color: transparent;
`;
