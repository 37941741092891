import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import React from 'react';
import { AuthProvider } from 'react-auth-kit';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { combineReducers, createStore } from 'redux';

import { ApolloClient, ApolloProvider, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { getAuthToken } from '@dynamic-labs/sdk-react-core';

import App from './App';
import { userSlice } from './Auth/slice';
import { Channel } from './Channel';
import { EditChannel } from './EditChannel';
import { Login } from './Login';
import { searchSlice } from './Search/slice';
import { User } from './User';
import { Video } from './Video';

const backendUri = process.env.REACT_APP_BACKEND_URI || "http://localhost:8000";

// HTTP link for queries and mutations
const httpLink = createUploadLink({
    uri: backendUri + "/graphql",
    credentials: "same-origin",
    headers: {
        authorization: getAuthToken()
    }
});

// WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(
    createClient({
        url: backendUri.replace(/^http/, "ws") + "/graphql",
        connectionParams: {
            authorization: getAuthToken()
        }
    })
);

// Split link to route subscription operations over WebSocket and other operations over HTTP
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink,
    httpLink
);

// TODO: https://height.app/gkSnvZYAS2/T-65- Check if Apollo Client
// contains the correct headers without reloading the app
const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
});

const rootReducer = combineReducers({
    [userSlice.name]: userSlice.reducer,
    [searchSlice.name]: searchSlice.reducer
});
const store = createStore(rootReducer);

// TODO: Add this back to the AuthProvider for https connection
// cookieSecure={window.location.protocol === "https:"}

export const PrivateRoutes = () => {
    return (
        <AuthProvider
            authType={"cookie"}
            authName={"_auth"}
            cookieDomain={window.location.hostname}
        >
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <Router>
                        <Routes>
                            <Route
                                path="/editChannel"
                                element={<EditChannel />}
                            />
                            <Route path="/video/:videoId" element={<Video />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/channel" element={<Channel />} />
                            <Route path="/" element={<App />} />
                            <Route path="/u/:username" element={<User />} />
                        </Routes>
                    </Router>
                </Provider>
            </ApolloProvider>
        </AuthProvider>
    );
};
