// external
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
    BigPlayButton,
    ClosedCaptionButton,
    ControlBar,
    Player
} from "video-react";

import { gql, useQuery } from "@apollo/client";

// local
import { Header } from "../components/Header";
import { VideoThumbnail } from "../User/VideoThumbnail";
import { videoMessages } from "./messages";
import { Sidebar } from "./Sidebar";
import {
    DescriptionWrapper,
    InfoImgWrapper,
    InfoWrapper,
    PlayerWrapper,
    RelatedText,
    RelatedVideos,
    RelatedWrapper,
    TranscriptWrapper
} from "./wrappers";

const GET_VIDEO_INFO = gql`
    query VIDEO($video_id: String!) {
        video(video_id: $video_id) {
            thumbnailUrl
            videoUrl
            title
            description
            upVotes
            downVotes
            createdAt
            id
            channel_id
        }
    }
`;

const GET_CHANNEL_INFO = gql`
    query CHANNEL($channel_id: Int!) {
        channel(channel_id: $channel_id) {
            imgUrl
            title
            description
            username
        }
    }
`;

const GET_VIDEOS = gql`
    query VIDEOS($channel_id: Int!) {
        videos(channel_id: $channel_id) {
            thumbnailUrl
            videoUrl
            title
            upVotes
            downVotes
            createdAt
            id
        }
    }
`;

function timestampToSeconds(timestamp: string) {
    const timeArray = timestamp.match(/\d+[hms]/g) || [];
    let totalSeconds = 0;

    timeArray.forEach((time: string) => {
        const unit = time.charAt(time.length - 1);
        const value = parseInt(time, 10);

        switch (unit) {
            case "h":
                totalSeconds += value * 3600; // 1 hour = 3600 seconds
                break;
            case "m":
                totalSeconds += value * 60; // 1 minute = 60 seconds
                break;
            case "s":
                totalSeconds += value;
                break;
            default:
                // Ignore unsupported units
                break;
        }
    });

    return totalSeconds;
}

export const Video: React.FC = () => {
    let { videoId } = useParams();
    const location = useLocation();
    const hashParams = new URLSearchParams(location.hash.substring(1));
    const timestamp = hashParams.get("t");
    let totalSeconds = 0;
    if (timestamp) {
        totalSeconds = timestampToSeconds(timestamp);
    }

    const { data, error } = useQuery(GET_VIDEO_INFO, {
        variables: { video_id: videoId || "1" }
    });

    const video = data?.video;
    const channel_id = video?.channel_id;
    const queriedData = useQuery(GET_CHANNEL_INFO, {
        variables: { channel_id: parseInt(channel_id || "1") }
    });
    const channel = queriedData?.data?.channel;

    const videoResults = useQuery(GET_VIDEOS, {
        variables: { channel_id: parseInt(channel_id) }
    });
    const videoData = videoResults?.data?.videos ?? [];
    const selectedTypeArray = ["info", "transcript", "chat"];
    const [selectedType, setSelectedType] = React.useState(
        selectedTypeArray[0]
    );
    return (
        <div>
            <Header />
            <PlayerWrapper>
                <Player
                    width={1200}
                    height={675}
                    fluid={false}
                    src={video?.videoUrl}
                    poster={video?.thumbnailUrl}
                    startTime={totalSeconds}
                    autoPlay={timestamp ? true : false}
                >
                    <track
                        kind="captions"
                        src="https://netboxvideos.s3.us-east-2.amazonaws.com/subtitles/survive.srt"
                        srcLang="en"
                        label="English"
                        default
                    />
                    <BigPlayButton position="center" />
                    <ControlBar autoHide={false}>
                        <ClosedCaptionButton order={7} />
                    </ControlBar>
                </Player>
            </PlayerWrapper>
            <div className="container">
                <DescriptionWrapper className="row">
                    <InfoWrapper
                        selected={selectedType === "info"}
                        className="col"
                        onClick={() => {
                            setSelectedType("info");
                        }}
                    >
                        <InfoImgWrapper src="/info.png"></InfoImgWrapper>
                        <div>{videoMessages.info}</div>
                    </InfoWrapper>
                    <TranscriptWrapper
                        selected={selectedType === "transcript"}
                        className="col"
                        onClick={() => {
                            setSelectedType("transcript");
                        }}
                    >
                        <InfoImgWrapper src="/transcript.png"></InfoImgWrapper>
                        <div>{videoMessages.transcript}</div>
                    </TranscriptWrapper>
                    {/* <TranscriptWrapper
						selected={selectedType === "chat"}
						className="col"
						onClick={() => {
							setSelectedType("chat");
						}}
					>
						<InfoImgWrapper src="/chat.png"></InfoImgWrapper>
						<div>Chat</div>
					</TranscriptWrapper> */}
                </DescriptionWrapper>
            </div>
            {selectedType === "info" ? (
                <Sidebar
                    videoTitle={video?.title ?? ""}
                    videoDescription={video?.description ?? ""}
                    channelTitle={channel?.title ?? ""}
                    channelImgUrl={channel?.imgUrl ?? ""}
                    upvotes={video?.upVotes ?? 0}
                    downvotes={video?.downVotes ?? 0}
                />
            ) : (
                <></>
            )}

            <RelatedWrapper>
                <RelatedText>{videoMessages.related}</RelatedText>
                <RelatedVideos>
                    {videoData.length > 0 ? (
                        videoData.map((video: any) => (
                            <VideoThumbnail
                                key={video.title}
                                url={video.thumbnailUrl}
                                title={video.title}
                                createdAt={video.createdAt}
                                videoId={video.id}
                            ></VideoThumbnail>
                        ))
                    ) : (
                        <></>
                    )}
                </RelatedVideos>
            </RelatedWrapper>
        </div>
    );
};
