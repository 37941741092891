import styled from "styled-components";

export const FileDropWrapper = styled.div`
    background-color: #e6f2fe;
    object-fit: none;
    border-radius: 8px;
    margin-top: 20px;
    width: 200px;
    height: 200px;
`;

/**
 * The Upload Image Wrapper
 */
export const UploadImageWrapper = styled.img`
    display: block;
    width: 100px;
    height: auto;
    margin: 0 auto;
    border: none;
    object-fit: none;
`;

export const TitleUploadWrapper = styled.div`
    color: #164069;
    font-weight: bold;
`;
