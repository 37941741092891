// external
import styled from "styled-components";

export const BlueWrapper = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    gap: 8px;

    position: absolute;
    width: 410px;
    height: 65px;
    left: 0px;
    bottom: 0px;

    /* Alice Blue */
    background: #e6f2fe;

    /* Grit Blue (15% Opacity) */
    border-top: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 0px 0px 4px 4px;

    /* Inside auto layout */
    flex: none;
    order: 3;
    flex-grow: 0;
`;

export const DateWrapper = styled.div`
    /* Sept 21, 2022 */
    width: 94px;
    height: 21px;

    /* Body */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    /* identical to box height, or 131% */
    text-align: center;
    letter-spacing: -0.03em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
`;

export const DescriptionTextWrapper = styled.div`
    position: absolute;
    width: 367px;
    height: 462px;
    left: 16px;
    top: 231px;
`;

export const FirstLinkWrapper = styled.button`
    /* First Name Field */
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 16px;

    width: 91.5px;
    height: 45px;
    background: #ffffff;

    /* Grit Blue (15% Opacity) */
    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 4px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
`;

export const NumberWrapper = styled.div`
    /* 63k subscribers */
    position: absolute;
    left: 60px;
    top: 111px;
    width: 85px;
    height: 16px;

    /* Detail */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    /* identical to box height, or 133% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
`;

export const ProfileImgWrapper = styled.img`
    /* Picture */
    position: absolute;
    left: 16px;
    top: 91px;
    width: 32px;
    height: 32px;

    background: url(image);

    /* Indigo Dye */
    border: 2px solid #164069;
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const ProfileTitleWrapper = styled.div`
    position: absolute;
    left: 60px;
    top: 88px;
    width: 139px;
    height: 19px;

    /* Body (Bold) */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    /* identical to box height, or 119% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const SidebarWrapper = styled.div`
    /* Sidebar Content */
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;

    position: absolute;
    width: 412px;
    height: 604px;
    top: 169px;
    left: 1235px;

    /* White */
    background: #ffffff;
    /* Grit Blue (15% Opacity) */
    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
`;

export const SubscribeButtonWrapper = styled.button<{ subscribed: boolean }>`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 13px;
    gap: 8px;

    position: absolute;
    right: 16px;
    top: 88px;
    width: 99px;
    height: 43px;

    /* Bay Blue */
    background: ${(props) => (props.subscribed ? "#4DA6FF" : "#C3E0FD")};
    border-radius: 3px;
    border-color: transparent;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

    /* Body (Bold) */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    /* identical to box height, or 119% */
    letter-spacing: -0.05em;

    /* Indigo Dye */
    color: ${(props) => (props.subscribed ? "#FFFFFF" : "#164069")};
`;

export const TitleWrapper = styled.div`
    width: 281px;
    height: 46px;

    /* Heading 3 */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    /* or 115% */

    letter-spacing: -0.04em;

    /* Indigo Dye */
    color: #164069;
`;

export const TopWrapper = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 3px;

    width: 410px;
    height: 73px;

    /* Alice Blue */
    background: #e6f2fe;

    /* Grit Blue (15% Opacity) */
    border-bottom: 1px solid rgba(1, 13, 25, 0.15);

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const ViewsWrapper = styled.div`
    /* 48,372 views */
    position: absolute;
    left: 16px;
    top: 141px;
    width: 290px;
    height: 21px;

    /* Body */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    /* or 119% */
    text-align: left;
    letter-spacing: -0.05em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;
