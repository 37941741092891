import {
    BlueWrapper,
    DateWrapper,
    DescriptionTextWrapper,
    FirstLinkWrapper,
    NumberWrapper,
    ProfileImgWrapper,
    ProfileTitleWrapper,
    SidebarWrapper,
    SubscribeButtonWrapper,
    TitleWrapper,
    TopWrapper,
    ViewsWrapper
} from "./wrappers";
import React, { useState } from "react";

import { DownVoteButton } from "../../components/DownVoteButton";
import { SOCIAL_MEDIA_ARRAY } from "./constants";
import { UpVoteButton } from "../../components/UpVoteButton";

export const Sidebar: React.FC<{
    videoTitle: string;
    videoDescription: string;
    channelTitle: string;
    channelImgUrl: string;
    upvotes: number;
    downvotes: number;
}> = ({
    videoTitle,
    videoDescription,
    channelTitle,
    channelImgUrl,
    upvotes,
    downvotes
}) => {
    const [subscribed, setSubscribed] = useState(false);

    // define channel and video
    return (
        <SidebarWrapper>
            <TopWrapper>
                <TitleWrapper>{videoTitle + " | " + channelTitle}</TitleWrapper>
                <DateWrapper>Sept 21, 2022</DateWrapper>
            </TopWrapper>
            <ProfileImgWrapper src={channelImgUrl}></ProfileImgWrapper>
            <ProfileTitleWrapper>{channelTitle}</ProfileTitleWrapper>
            {/* TODO: Restructure this button to be reused */}
            <SubscribeButtonWrapper
                subscribed={subscribed}
                onClick={() => {
                    setSubscribed(!subscribed);
                }}
            >
                {subscribed ? "Subscribed" : "Subscribe"}
            </SubscribeButtonWrapper>
            <NumberWrapper>63k subscribers</NumberWrapper>
            <ViewsWrapper>
                {"48,372 views" +
                    " · " +
                    upvotes +
                    " upvotes" +
                    " · " +
                    downvotes +
                    " downvotes"}
            </ViewsWrapper>
            <UpVoteButton />
            <DownVoteButton />
            <DescriptionTextWrapper>{videoDescription}</DescriptionTextWrapper>
            <BlueWrapper>
                {SOCIAL_MEDIA_ARRAY.map((socialMedia) => {
                    return (
                        <FirstLinkWrapper>
                            <img src={"/" + socialMedia + ".png"} height="18" />
                        </FirstLinkWrapper>
                    );
                })}
            </BlueWrapper>
        </SidebarWrapper>
    );
};
