import styled from "styled-components";

export const UploadsTitleWrapper = styled.div`
    /* Uploads */
    position: absolute;
    width: 384px;
    height: 30px;
    left: 132px;
    top: 432px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;

    color: #000000;
`;

/**
 * The Video Grip wrapper
 */
export const VideoGripWrapper = styled.div`
    position: absolute;
    grid-template-columns: 1fr 1fr;
    top: 500px;
    left: 132px;
    width: 100%;
`;

/**
 * The Profile Image wrapper
 */
export const VideoPreviewWrapper = styled.div`
    position: absolute;
    top: 480px;
`;

/**
 * The Profile Image wrapper
 */
export const SecondVideoPreviewWrapper = styled.div`
    position: absolute;
    top: 480px;
    left: 450px;
`;

/**
 * The Profile Image wrapper
 */
export const ThirdVideoPreviewWrapper = styled.div`
    position: absolute;
    top: 480px;
    left: 900px;
`;
