import styled from "styled-components";

/**
 * The Player wrapper
 */
export const PlayerWrapper = styled.div`
    box-sizing: border-box;

    position: absolute;
    width: 706.56px;
    height: 397.5px;
    left: 500px;
    top: 575px;
    border: 1px solid #164069;
    border-radius: 10px;
`;

/**
 * The Right wrapper
 */
export const CenterWrapper = styled.div`
    /* The economic content platform you and your fans will love */
    position: absolute;
    width: 835px;
    height: 136px;
    left: 397px;
    top: 170.5px;

    /* Hero Heading */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 66px;
    line-height: 68px;

    text-align: center;
    letter-spacing: -0.075em;

    /* Dodger Blue */
    color: #4da6ff;
`;

/**
 * The Content wrapper
 */
export const ContentWrapper = styled.div`
    position: absolute;
    width: 400px;
    height: 54px;
    left: 609px;
    top: 323.5px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    /* or 112% */

    text-align: center;
    letter-spacing: -0.03em;

    /* Indigo Dye */
    color: #164069;
`;

/**
 * The Create Button wrapper
 */
export const CreateButtonWrapper = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;

    position: absolute;
    width: 200px;
    height: 60px;
    left: 632px;
    top: 434.5px;

    /* Bay Blue */
    background: #c3e0fd;
    border-radius: 3px;
    border-color: transparent;
`;

export const VideosButtonWrapper = styled.button`
    /* CTA Button */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;

    position: absolute;
    width: 200px;
    height: 60px;
    left: 847px;
    top: 434.5px;

    border-radius: 3px;
    border-color: transparent;
`;

/**
 * The Start Creating Button wrapper
 */
export const StartTextWrapper = styled.div`
    width: 120px;
    height: 19px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height, or 106% */
    letter-spacing: -0.05em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const VideosTextWrapper = styled.div`
    /* Watch videos now */
    width: 144px;
    height: 19px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height, or 106% */

    letter-spacing: -0.05em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

/**
 * The Start Creating Button wrapper
 */
export const NetboxImgWrapper = styled.img`
    object-fit: fill;
    width: 1680px;
    height: 890px;
`;

export const BackgroundWrapper = styled.div`
    position: absolute;
    width: 100%;
    background-color: ##fafeff;
    height: 1070px;
`;

/**
 * The Community wrapper
 */
export const SecondImgWrapper = styled.img`
    /* community */
    position: absolute;
    width: 581px;
    height: 342px;
    left: 173px;
    top: 611px;
`;

/**
 * The Community wrapper
 */
export const ThirdImgWrapper = styled.img`
    /* community */
    position: absolute;
    width: 581px;
    height: 342px;
    left: 986px;
    top: 611px;
`;

export const CTAButtonWrapper = styled.button`
    /* CTA Button */
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 6px;

    position: absolute;
    width: 241.17px;
    height: 31px;
    left: 674px;
    top: 111.5px;

    /* Alice Blue */
    background: #e6f2fe;

    /* Grit Blue (15% Opacity) */
    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 100px;
`;

export const TextWrapper = styled.div`
    /* Netbox is now in Beta! */
    width: 149px;
    height: 19px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 158% */

    letter-spacing: -0.05em;
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;
