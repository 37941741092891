import "bootstrap/dist/css/bootstrap.min.css";
import "./Demo.css";

import React, { useCallback, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { FileDrop } from "react-file-drop";

import { useMutation } from "@apollo/client";

import { StateUser, StateVideo } from "../types";
import { uploadVideoMessages } from "./messages";
import { CREATE_VIDEO } from "./queries";
import {
    FailedTitleWrapper,
    FailedWrapper,
    RestartWrapper,
    SubtitleWrapper,
    TitleUploadWrapper,
    UploadImgWrapper,
    UploadModalWrapper
} from "./wrappers";

interface UploadProps {
    /** The current state of the modal */
    state: StateVideo;
    /** The state of modal */
    setState: (state: StateVideo) => void;
    /** Handle drop */
    uploadFile: (files: FileList) => void;
    /** Signed in User */
    user?: StateUser;
    /** Set failure of upload */
    setFailure?: (failure: boolean) => void;
}

const DropZone: React.FC<
    UploadProps & { setUploadProgress: (progress: number) => void }
> = ({ state, setState, user, uploadFile, setUploadProgress }) => {
    const [createVideo] = useMutation(CREATE_VIDEO);

    window.addEventListener(
        "dragover",
        function (e) {
            e = e || event;
            e.preventDefault();
        },
        false
    );

    window.addEventListener(
        "drop",
        function (e) {
            e = e || event;
            e.preventDefault();
        },
        false
    );

    const handleDrop = async (files: FileList | null) => {
        if (!files || !files.item(0)) return;
        const file = files.item(0)!;

        const titleWithoutExtension = file.name
            .split(".")
            .slice(0, -1)
            .join(".");

        setState({ index: 1, title: titleWithoutExtension });
        const res = await createVideo({
            variables: {
                channel_id: parseInt(user?.channelId || "1"),
                title: titleWithoutExtension
            }
        });

        setState({
            index: 1,
            title: titleWithoutExtension,
            description: state?.description,
            videoUrl: state?.videoUrl,
            thumbnailUrl: state?.thumbnailUrl,
            videoId: res.data.createVideo.id,
            audience: state.audience,
            emailPreference: state?.emailPreference
        });

        uploadFile(files);
    };

    const handleClick = useCallback(() => {
        const input = document.createElement("input");
        input.type = "file";
        input.multiple = true;
        input.onchange = (event) => {
            const files = (event.target as HTMLInputElement).files;
            if (files) handleDrop(files);
        };
        input.click();
    }, []);

    return (
        <div
            style={{
                border: "1px",
                width: 600,
                color: "black",
                padding: 20,
                margin: "0 auto"
            }}
        >
            <FileDrop onDrop={handleDrop} onTargetClick={handleClick}>
                <TitleUploadWrapper>
                    {uploadVideoMessages.dragAndDrop}
                </TitleUploadWrapper>
                <br />
                <UploadImgWrapper src="/upload.png" />
                <SubtitleWrapper>
                    {uploadVideoMessages.privateVideos}
                </SubtitleWrapper>
            </FileDrop>
        </div>
    );
};

export const UploadButton: React.FC<UploadProps> = ({
    state,
    setState,
    uploadFile,
    user
}) => {
    const [failure, setFailure] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    if (failure) {
        return (
            <>
                <Modal.Header>
                    <Modal.Title>
                        {uploadVideoMessages.failedUpload}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FailedWrapper>
                        <FailedTitleWrapper>
                            {uploadVideoMessages.failedUpload}
                        </FailedTitleWrapper>
                        <UploadImgWrapper src="/failed.png" />
                        <SubtitleWrapper>
                            {uploadVideoMessages.notMp4}
                        </SubtitleWrapper>
                    </FailedWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <RestartWrapper onClick={() => setFailure(false)}>
                        {uploadVideoMessages.uploadAnother}
                    </RestartWrapper>
                </Modal.Footer>
            </>
        );
    }

    const handleUploadProgress = (progress: number) => {
        setUploadProgress(progress);
        setState({ ...state, progress });
    };

    return (
        <>
            <Modal.Header>
                <Modal.Title>{uploadVideoMessages.uploadVideos}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DropZone
                    state={state}
                    setState={setState}
                    uploadFile={uploadFile}
                    user={user}
                    setUploadProgress={handleUploadProgress}
                />
            </Modal.Body>
            <Modal.Footer>
                {uploadProgress > 0 && uploadProgress < 100 && (
                    <div style={{ width: "100%" }}>
                        <ProgressBar
                            now={uploadProgress}
                            label={`${Math.round(uploadProgress)}%`}
                        />
                    </div>
                )}
                <UploadModalWrapper
                    onClick={() => setState({ ...state, index: 0 })}
                >
                    {uploadVideoMessages.upload}
                </UploadModalWrapper>
            </Modal.Footer>
        </>
    );
};
