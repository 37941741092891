import styled from "styled-components";

/**
 * The Upload Modal Wrapper Button
 */
export const UploadModalWrapper = styled.button`
    display: flex;
    width: 200px;
    height: 60.117px;
    padding: 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--bay-blue, #c3e0fd);
    border-color: transparent;
`;

/**
 * The Upload Modal Wrapper Button
 */
export const RestartWrapper = styled.button`
    display: flex;
    width: 200px;
    height: 60.117px;
    padding: 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--error-saturated, #f00);
    border-color: transparent;
    color: var(--Error, #fee6e6);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px; /* 105.556% */
    letter-spacing: -0.9px;
`;

/**
 * The Title of the Upload Wrapper
 */
export const TitleUploadWrapper = styled.div`
    position: absolute;
    width: 439px;
    height: 26px;
    top: 240px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;

    color: #000000;
`;

/**
 * The Failed Title of the Upload Wrapper
 */
export const FailedTitleWrapper = styled.div`
    position: absolute;
    width: 439px;
    height: 26px;
    top: 240px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;

    color: #ff0000;
`;

/**
 * The Subtitle Wrapper
 */
export const SubtitleWrapper = styled.div`
    position: absolute;
    top: 270px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: center;

    color: #989898;
`;

export const FailedWrapper = styled.div`
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
`;

/**
 * The Upload Image Wrapper
 */
export const UploadImgWrapper = styled.img`
    top: 40px;
    width: 150px;
    height: 150px;
    object-fit: fill;
`;
