import styled from 'styled-components';

export const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 168.75px;
    border-radius: 4px;
    background-color: #ebebeb;
    color: #5f5f5f;
`;

export const ImgThumbnailWrapper = styled.img`
    width: 300px;

    border-radius: 4px;
`;

export const DetailsWrapper = styled.div`
    display: flex;
    width: 300px;
    padding: 0 0 16px;

    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: var(--alice-blue, #e6f2fe);
`;

export const DescriptionWrapper = styled.div`
    color: var(--dodger-blue, #4da6ff);

    margin-left: 8px;
    margin-top: 8px;
    /* Detail */
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    letter-spacing: -0.36px;
`;

export const TitleWrapper = styled.div`
    color: var(--indigo-dye, #164069);

    margin-left: 8px;
    /* Body */
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 131.25% */
    letter-spacing: -0.48px;
`;
