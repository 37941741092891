import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client'; // Update the import

import { PrivateRoutes } from './PrivateRoutes';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById("root")!); // Create a root
root.render(
    <React.StrictMode>
        <PrivateRoutes />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
