// external
import styled from "styled-components";

export const DownVoteWrapper = styled.button<{ downvote: boolean }>`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 13px;
    gap: 6px;

    position: absolute;
    right: 12px;
    top: 174px;
    width: 188px;
    height: 44px;

    /* Bay Blue */
    color: ${(props) => (props.downvote ? "#FFFFFF" : "#164069")};
    background: ${(props) => (props.downvote ? "#FF0000" : "#c3e0fd")};
    border-radius: 3px;
    border-color: transparent;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
`;
