import styled from "styled-components";

/**
 * The Subscriber wrapper
 */
export const SubscriberWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 327px;
	left: 0px;
	top: 80px;

	background: #f7f7f7;
	border-radius: 8px 0px 0px 0px;
`;

/**
 * The Profile Image wrapper
 */
export const ProfileWrapper = styled.img`
	position: absolute;
	width: 200px;
	height: 200px;
	left: 111px;
	top: 40px;

	border-radius: 100px 100px 100px 100px;
`;

/**
 * The Title wrapper
 */
export const TitleWrapper = styled.div`
	position: absolute;
	width: 299px;
	height: 34px;
	left: 334px;
	top: 120px;

	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 34px;
	line-height: 40px;

	color: #000000;
`;

/**
 * The Subtitle wrapper
 */
export const SubtitleWrapper = styled.div`
	position: absolute;
	width: 384px;
	height: 30px;
	left: 334px;
	top: 177px;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 30px;

	color: #000000;
`;

export const SubscribeButtonWrapper = styled.input`
	position: absolute;
	width: 234px;
	height: 54px;
	left: 1072px;
	top: 140px;

	border: none;
	background: #469df8;
	border-radius: 6px;

	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;

	color: #ffffff;
`;

export const SubscribeInputWrapper = styled.input`
	position: absolute;
	width: 445px;
	height: 54px;
	left: 861px;
	top: 140px;

	border: none;
	border-radius: 6px;
`;

export const UploadsTitleWrapper = styled.div`
	/* Uploads */
	position: absolute;
	width: 384px;
	height: 30px;
	left: 132px;
	top: 432px;

	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 30px;

	color: #000000;
`;

/**
 * The Profile Image wrapper
 */
export const VideoPreviewWrapper = styled.div`
	position: absolute;
	top: 410px;
`;

/**
 * The Profile Image wrapper
 */
export const SecondVideoPreviewWrapper = styled.div`
	position: absolute;
	top: 410px;
	left: 450px;
`;

/**
 * The Profile Image wrapper
 */
export const ThirdVideoPreviewWrapper = styled.div`
	position: absolute;
	top: 410px;
	left: 900px;
`;
