// external
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { useDebounce } from "use-debounce";

import { gql, useQuery } from "@apollo/client";

// local
import { selectSearchValue } from "../Search/selectors";
import { setSearch } from "../Search/slice";
import { searchTableMessages } from "./messages";
import {
  ButtonWrapper,
  ProfileImg,
  SmallSubtitleWrapper,
  SmallTitleWrapper,
  SmallVideoWrapper,
  SubtitleWrapper,
  TableCenter,
  TitleWrapper
} from "./wrappers";

// Types
import type { Column } from "react-table";

const CHANNELS = gql`
  query GET_CHANNELS {
    channels {
      imgUrl
      title
      description
      username
    }
  }
`;

const SEARCH_VIDEOS = gql`
  query SearchVideos($query: String!) {
    semanticSearchVideos(query: $query) {
      thumbnailUrl
      videoUrl
      title
      description
      upVotes
      downVotes
      createdAt
      timestamp
      quote
      videoId
      createdBy {
        imgUrl
        description
        username
        id
      }
    }
  }
`;

export interface TableProps {
  searchValue?: string;
}

const TopTable: React.FC<TableProps> = (props) => {
  const { searchValue } = props;

  const { loading, error, data } = useQuery(CHANNELS);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // TODO: NET-16 - fix when the username does not exist
  const navigateHome = (username: string) => {
    dispatch(setSearch(""));
    navigate(`u/${username}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Channels",
        accessor: "imgUrl",
        Cell: (props: any) => (
          <ButtonWrapper
            onClick={() => navigateHome(props.row.original.username)}
          >
            <ProfileImg
              src={props.row.original.imgUrl}
              width={42}
              alt="Player"
            />
          </ButtonWrapper>
        )
      } as Column<{ title: string; description: string }>,
      {
        accessor: "title",
        Cell: (props: any) => (
          <ButtonWrapper
            onClick={() => navigateHome(props.row.original.username)}
          >
            <TitleWrapper>{props.row.original.title}</TitleWrapper>
            <SubtitleWrapper>{props.row.original.description}</SubtitleWrapper>
          </ButtonWrapper>
        )
      } as Column<{ title: string; description: string }>
    ],
    []
  );
  const newData = data?.channels ?? [];

  const filteredData = useMemo(
    () =>
      newData.filter(
        (row: any) =>
          searchValue &&
          (row.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            row.description.toLowerCase().includes(searchValue.toLowerCase()))
      ),
    [newData, searchValue]
  );

  const tableInstance = useTable({
    columns,
    data: filteredData
  });
  if (loading) return <p></p>;
  if (error) return <p>{searchTableMessages.error}</p>;
  if (filteredData.length === 0) {
    return <></>;
  }

  const { getTableProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div style={{ maxWidth: "100%" }}>
      <TableCenter {...getTableProps()} style={{ maxWidth: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr>
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{
                        padding: "12px",
                        gap: "10px"
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableCenter>
    </div>
  );
};

const formatTimestamp = (timestamp: string): string => {
  const date = new Date(`1970-01-01T${timestamp}Z`);
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  return `${minutes}m:${seconds}s`;
};

const formatTime = (value: number): string =>
  value < 10 ? `0${value}` : `${value}`;

const getTimestamp = (timestamp: string): string => {
  console.log("Get timestamp:", timestamp);
  const date = new Date(`1970-01-01T${timestamp}Z`);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const formattedHours = hours > 0 ? `${formatTime(hours)}h` : "";
  const formattedMinutes = minutes > 0 ? `${formatTime(minutes)}m` : "";
  const formattedSeconds = formatTime(seconds);
  return `${formattedHours}${formattedMinutes}${formattedSeconds}s`;
};

const BottomTable: React.FC<TableProps> = (props) => {
  const { searchValue } = props;

  const [debouncedSearch] = useDebounce(searchValue, 100);

  const { loading, error, data } = useQuery(CHANNELS);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateHome = (videoId: string, timestamp: string) => {
    dispatch(setSearch(""));
    navigate(`video/${videoId}#t=${getTimestamp(timestamp)}`);
  };

  const debouncedResult = useQuery(SEARCH_VIDEOS, {
    variables: {
      query: debouncedSearch
    },
    skip: !debouncedSearch
  });
  const debouncedData = debouncedResult?.data?.semanticSearchVideos ?? [];

  const columns = useMemo(
    () => [
      {
        Header: "Transcripts",
        accessor: "imgUrl",
        Cell: (props: any) => (
          <ButtonWrapper
            onClick={() =>
              navigateHome(
                props.row.original.videoId,
                props.row.original.timestamp
              )
            }
          >
            <SmallVideoWrapper
              src={props.row.original.thumbnailUrl}
              alt="Player"
            />
          </ButtonWrapper>
        )
      } as Column<{ title: string; description: string }>,
      {
        accessor: "title",
        Cell: (props: any) => (
          <ButtonWrapper
            onClick={() =>
              navigateHome(
                props.row.original.videoId,
                props.row.original.timestamp
              )
            }
          >
            <SmallTitleWrapper>{props.row.original.title}</SmallTitleWrapper>
            <SmallSubtitleWrapper>
              <span>{props.row.original.quote} • </span>
              {formatTimestamp(props.row.original.timestamp)}
            </SmallSubtitleWrapper>
          </ButtonWrapper>
        )
      } as Column<{ title: string; description: string }>
    ],
    []
  );

  const tableInstance = useTable({
    columns,
    data: debouncedData
  });

  if (loading) return <p></p>;
  if (error) return <p>{searchTableMessages.error}</p>;
  if (debouncedData.length === 0) {
    return <></>;
  }
  const { getTableProps, headerGroups, rows, prepareRow } = tableInstance;
  return (
    <div style={{ maxWidth: "100%" }}>
      <TableCenter {...getTableProps()} style={{ maxWidth: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr>
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{
                        padding: "12px",
                        gap: "10px"
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableCenter>
    </div>
  );
};

export const SearchTable: React.FC = () => {
  const searchValue = useSelector(selectSearchValue);

  return (
    <>
      <TopTable key="top-table" searchValue={searchValue} />
      <BottomTable key="bottom-table" searchValue={searchValue} />
    </>
  );
};
