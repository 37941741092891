// external
import React from 'react';

import { BoxWrapper, InactiveBoxWrapper } from './wrappers';

export function BottomTitle({
    index,
    title,
    isCurrent,
    onClick
}: {
    index: number;
    title: string;
    isCurrent: boolean;
    onClick: () => void;
}) {
    const newIndex = index - 1;
    const leftAxis = (40 + 230 * newIndex).toString() + "px";
    const width =
        newIndex === 0
            ? "77px" // width for index 0
            : newIndex === 1
            ? "90px" // width for index 1
            : newIndex === 2
            ? "74px" // width for index 2
            : "77px";

    return isCurrent ? (
        <BoxWrapper x={leftAxis} width={width} onClick={onClick}>
            {title}
        </BoxWrapper>
    ) : (
        <InactiveBoxWrapper x={leftAxis} onClick={onClick}>
            {title}{" "}
        </InactiveBoxWrapper>
    );
}
