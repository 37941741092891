// external
import styled from "styled-components";

export const ThirdWrapper = styled.div`
    position: absolute;
    width: 1680px;
    height: 930px;
    left: 0px;
    top: 2031px;

    /* Grit Blue */

    background: #010d19;
    border-radius: 10px;
`;

export const TitleWrapper = styled.div`
    position: absolute;
    width: 68px;
    height: 19px;
    left: 147px;
    top: 2267.31px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height, or 106% */

    letter-spacing: -0.05em;

    /* Alice Blue */

    color: #e6f2fe;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const SubtitleWrapper = styled.div`
    position: absolute;
    width: 620.54px;
    height: 136px;
    left: 147px;
    top: 2321.31px;

    /* Hero Heading */

    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 66px;
    line-height: 68px;
    /* or 103% */

    letter-spacing: -0.075em;

    /* Dodger Blue */

    color: #4da6ff;
`;

export const DescriptionWrapper = styled.div`
    position: absolute;
    width: 537.37px;
    height: 162px;
    left: 147px;
    top: 2492.31px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    /* or 112% */

    letter-spacing: -0.03em;

    /* Alice Blue */

    color: #e6f2fe;
`;

export const HeroImgWrapper = styled.img`
    position: absolute;
    width: 1017px;
    height: 930px;
    left: 663px;
    top: 1981px;
`;
