// local
import {
    ButtonWrapper,
    PreviewWrapper,
    SubtitleWrapper,
    TitleWrapper,
    VideoImgWrapper
} from "./wrappers";

// libraries
import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * The theme
 */
export interface VideoProps {
    title: string;
    url: string;
    createdAt: string;
    videoId: number;
}

/*
 * Video Thumbnail preview that can be clicked
 */

export const VideoThumbnail: React.FC<VideoProps> = (props) => {
    const { url, createdAt, title, videoId } = props;
    const date = new Date(parseInt(createdAt));
    const navigate = useNavigate();

    const displayedVal =
        (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear();
    return (
        <ButtonWrapper onClick={() => navigate(`/video/${videoId}`)}>
            <PreviewWrapper>
                <VideoImgWrapper src={url} />
                <TitleWrapper>{title}</TitleWrapper>
                <SubtitleWrapper>{displayedVal}</SubtitleWrapper>
            </PreviewWrapper>
        </ButtonWrapper>
    );
};
