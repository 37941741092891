// external
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { gql, useMutation, useQuery } from "@apollo/client";

// local
import { selectUserValue } from "../../Auth/selectors";
import { SubscribeButton } from "../../components/SubscribeButton";
import { subscriberViewMessages } from "./messages";
import {
    ProfileWrapper,
    SubscribeInputWrapper,
    SubscriberWrapper,
    SubtitleWrapper,
    TitleWrapper
} from "./wrappers";

const SUBSCRIBE = gql`
    mutation SUBSCRIBE($email: String!, $channel: Int!) {
        subscribe(email: $email, channel: $channel)
    }
`;

const USER_SUBSCRIPTIONS = gql`
    query USER_SUBSCRIPTIONS($userId: String) {
        userSubscriptions(userId: $userId) {
            channelId
        }
    }
`;

/**
 * The Subscriber Props theme
 */
export interface SubscriberProps {
    description: string;
    imgUrl: string;
    title: string;
    id: number;
}

type IFormInput = {
    email: string;
};

export const SubscriberView: React.FC<SubscriberProps> = (props) => {
    const { register, handleSubmit } = useForm<IFormInput>();
    const { imgUrl, description, title, id } = props;
    const [mutateFunction, { data }] = useMutation(SUBSCRIBE);
    const user = useSelector(selectUserValue);

    const { data: userSubsArray } = useQuery(USER_SUBSCRIPTIONS, {
        variables: { userId: user.id }
    });

    const channelIds = userSubsArray?.userSubscriptions.map(
        (sub: any) => sub.channelId
    );
    const isSubscribed = channelIds ? channelIds.includes(id) : undefined;

    const onSubmit: SubmitHandler<IFormInput> = ({ email }) =>
        mutateFunction({
            variables: {
                email,
                channel: Number(id)
            }
        });

    return (
        <SubscriberWrapper>
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <ProfileWrapper src={imgUrl} />
            <TitleWrapper>{title}</TitleWrapper>
            <SubtitleWrapper>{description}</SubtitleWrapper>
            {/* <SubscribeInputWrapper
                    placeholder={subscriberViewMessages.typeYourEmail}
                    {...register("email")}
                /> */}
            <SubscribeButton subscribed={isSubscribed} channel={Number(id)} />
            {/* </form> */}
        </SubscriberWrapper>
    );
};
