import React, { useState } from "react";

import { DownVoteWrapper } from "./wrappers";

export const DownVoteButton: React.FC<{}> = ({}) => {
	const [clicked, setClicked] = useState(false);

	return (
		<DownVoteWrapper
			downvote={clicked}
			onClick={() => {
				setClicked(!clicked);
			}}
		>
			<img src={clicked ? "/downvoted.png" : "/downvote.png"} />
			{"Downvote"}
		</DownVoteWrapper>
	);
};
