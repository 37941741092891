import { createSelector } from "reselect";

import { SearchState } from "./slice";

/**
 * The selector for the `Privacies` state store slice
 */
export const getSearchState = (state: any): SearchState => state["search"];

/**
 * The privacy center policies that contains all local changes
 */
export const selectSearchValue = createSelector(
    getSearchState,
    ({ searchValue }) => searchValue
);
