// external
import React from "react";

// local
import { Header } from "../components/Header";
import { VideoPreview } from "../components/Subscriber/VideoPreview";
import {
    AnnualInput,
    AnnualWrapper,
    CancelButtonWrapper,
    FoundingInput,
    FoundingWrapper,
    MonthlyInput,
    MonthlyWrapper,
    ProfileWrapper,
    SaveButtonWrapper,
    SecondVideoPreviewWrapper,
    SubscriberWrapper,
    SubtitleInputWrapper,
    ThirdVideoPreviewWrapper,
    TitleInputWrapper,
    UploadsTitleWrapper,
    VideoPreviewWrapper
} from "./wrappers";

export function EditChannel() {
    return (
        <React.Fragment>
            <Header />
            <SubscriberWrapper />
            <ProfileWrapper src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" />
            <TitleInputWrapper placeholder={"Title"} />
            <SubtitleInputWrapper placeholder={"Subtitle"} />
            <MonthlyWrapper>Monthly</MonthlyWrapper>
            <MonthlyInput placeholder={"$10.99/mo"} />
            <AnnualWrapper>Annual</AnnualWrapper>
            <AnnualInput placeholder={"$110/year"} />
            <FoundingWrapper>Founding</FoundingWrapper>
            <FoundingInput placeholder={"$1500/year"} />
            <SaveButtonWrapper>Save</SaveButtonWrapper>
            <CancelButtonWrapper>Cancel</CancelButtonWrapper>
            <UploadsTitleWrapper>Uploads</UploadsTitleWrapper>
            <VideoPreviewWrapper>
                <VideoPreview></VideoPreview>
            </VideoPreviewWrapper>
            <SecondVideoPreviewWrapper>
                <VideoPreview></VideoPreview>
            </SecondVideoPreviewWrapper>
            <ThirdVideoPreviewWrapper>
                <VideoPreview></VideoPreview>
            </ThirdVideoPreviewWrapper>
        </React.Fragment>
    );
}
