import styled from 'styled-components';

export const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const InputWrapper = styled.input`
    /* Auto layout */
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    /* position: absolute; */
    width: 448px;
    height: 53px;

    /* Grit Blue (15% Opacity) */
    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 4px;
    margin-bottom: 4px;
`;

export const TextAreaWrapper = styled.textarea`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    width: 448px;
    min-height: 100px;
    max-height: 240px;
    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 4px;
    margin-bottom: 4px;
    resize: vertical;
`;
