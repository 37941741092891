import "./App.css";

import React from "react";

import { Header } from "./components/Header";
import { Subscriber } from "./components/Subscriber";

export const Channel = () => {
    return (
        <React.Fragment>
            <Header />
            <Subscriber></Subscriber>
        </React.Fragment>
    );
};
