// external
import React, { ChangeEvent } from "react";

// local
import { setSearch } from "../../Search/slice";
import { SearchWrapper } from "./wrappers";

/**
 * Props
 */
export type SearchProps = {
    /** The delay in ms before calling onChange */
    delay?: number;
    /** Callback that occurs on search change */
    onChange?: (newValue: string) => void;
    /** The search placeholder message */
    placeholder?: string;
    /** The current search value */
    value: string;
};

const defaultProps = {
    delay: 350,
    placeholder: "Search",
    onChange: () => null,
    value: ""
};

/**
 * Make default props required
 */
type PropsWithDefaults = SearchProps;

/**
 * The search value
 */
export type SearchState = {
    /** The search value */
    value: string;
};

/**
 * An input with a search icon
 */
class Search extends React.Component<SearchProps, SearchState> {
    /** The default props */
    public static defaultProps = defaultProps;

    /**
     * Set the value
     *
     * @param props - The search props
     */
    public constructor(props: PropsWithDefaults) {
        super(props);

        // Set initial value
        const { value } = props;
        this.state = { value };
    }

    /**
     * Callback when search stops typing
     *
     * @param value - The search value
     */
    public callOnChange = (value: string): void => {
        const { onChange } = this.props as PropsWithDefaults;

        // Call onChange
        if (onChange) {
            onChange(value);
            setSearch(value);
        }
    };

    /**
     * Callback that occurs on search change
     *
     * @param e - An input change event
     */
    public onChange = ({
        target: { value }
    }: ChangeEvent<{
        /** The new value */
        value: string;
    }>): void => {
        const { delay } = this.props as PropsWithDefaults;

        // Set text
        this.setState({ value });

        if (!delay) {
            this.callOnChange(value);
        } else {
            // Delay the onChange
            setTimeout(
                () => this.state.value === value && this.callOnChange(value),
                delay
            );
        }
    };

    /**
     * Render the input search
     *
     * @returns The JSX Component for the Search
     */
    public render(): JSX.Element {
        const { placeholder, ...searchProps } = this.props as PropsWithDefaults;
        const { value } = this.state;
        return (
            <SearchWrapper
                placeholder={placeholder}
                // {...searchProps}
                onChange={this.onChange}
                value={value}
            />
        );
    }
}

export default Search;
