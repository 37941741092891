// external
import React from 'react';

import { VideoPreview } from './VideoPreview';
// local
import {
    ProfileWrapper, SecondVideoPreviewWrapper, SubscribeButtonWrapper, SubscribeInputWrapper,
    SubscriberWrapper, SubtitleWrapper, ThirdVideoPreviewWrapper, TitleWrapper, UploadsTitleWrapper,
    VideoPreviewWrapper
} from './wrappers';

/**
 * Props
 */

export const Subscriber = () => {
    return (
        <>
            <SubscriberWrapper>
                <ProfileWrapper src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" />
                <TitleWrapper>Pomp Creator</TitleWrapper>
                <SubtitleWrapper>
                    The best videos on the internet
                </SubtitleWrapper>
                {/* <SubscribeInputWrapper placeholder="Type your email" /> */}
                <SubscribeButtonWrapper>Subscribe</SubscribeButtonWrapper>
            </SubscriberWrapper>
            <UploadsTitleWrapper>Uploads</UploadsTitleWrapper>
            <VideoPreviewWrapper>
                <VideoPreview></VideoPreview>
            </VideoPreviewWrapper>
            <SecondVideoPreviewWrapper>
                <VideoPreview></VideoPreview>
            </SecondVideoPreviewWrapper>
            <ThirdVideoPreviewWrapper>
                <VideoPreview></VideoPreview>
            </ThirdVideoPreviewWrapper>
        </>
    );
};
