import { createSlice } from "@reduxjs/toolkit";

/**
 * The Search state
 */
export interface SearchState {
    /** The policies that exists in the database and is deployed. */
    searchValue?: string;
}

export const searchSlice = createSlice({
    name: "search",
    initialState: {
        searchValue: ""
    } as SearchState,
    reducers: {
        setSearch: (state, { payload }) => {
            state.searchValue = payload;
        }
    }
});

export const { setSearch } = searchSlice.actions;
