import styled from "styled-components";

export const BackgroundWrapper = styled.div`
    position: absolute;
    width: 1630px;
    height: 834px;
    left: 25px;
    top: 2931px;

    /* Alice Blue */
    background: #e6f2fe;
    border-radius: 10px;
`;

export const PaymentWrapper = styled.img`
    /* Payout UI Mockup */

    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 40px;

    position: absolute;
    width: 320px;
    height: 468px;
    left: 286px;
    top: 3067px;

    /* White */
    background: #ffffff;
    /* Grit Blue (15% Opacity) */

    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 5px;
`;

export const HeaderWrapper = styled.div`
    /* The Idea */
    position: absolute;
    width: 68px;
    height: 19px;
    left: 806px;
    top: 3124px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height, or 106% */
    letter-spacing: -0.05em;

    /* Indigo Dye */
    color: #164069;
`;

export const TitleWrapper = styled.div`
    position: absolute;
    width: 587px;
    height: 136px;
    left: 806px;
    top: 3178px;

    /* Hero Heading */

    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 66px;
    line-height: 68px;
    /* or 103% */

    letter-spacing: -0.075em;

    /* Dodger Blue */

    color: #4da6ff;
`;

export const SubtitleWrapper = styled.div`
    position: absolute;
    width: 480px;
    height: 135px;
    left: 806px;
    top: 3349px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    /* or 112% */

    letter-spacing: -0.03em;

    /* Indigo Dye */

    color: #164069;
`;

export const SignUpButtonWrapper = styled.button`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;

    position: absolute;
    width: 200px;
    height: 60px;
    left: 806px;
    top: 3519px;

    /* Bay Blue */
    background: #c3e0fd;
    border-radius: 10px;
    border-color: transparent;
`;
