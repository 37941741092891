import { gql } from '@apollo/client';

export const DYNAMIC_SIGN_IN = gql`
    mutation DYNAMIC_SIGN_IN {
        dynamicSignIn {
            id
            firstName
            lastName
            username
            email
            picture
            channelId
        }
    }
`;
