import { gql } from '@apollo/client';

export const CREATE_VIDEO = gql`
    mutation ($channel_id: Int!, $title: String) {
        createVideo(channel_id: $channel_id, title: $title) {
            success
            id
        }
    }
`;
