import { gql } from "@apollo/client";

export const UPLOAD_THUMBNAIL = gql`
    mutation UPLOAD_THUMBNAIL($file: Upload!, $videoId: String!) {
        uploadThumbnail(file: $file, videoId: $videoId) {
            success
            url
        }
    }
`;
