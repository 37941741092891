import styled from "styled-components";

/**
 * The Place Wrapper
 */
export const PlaceWrapper = styled.div`
    /* A place for independent creators who want to own their audience */
    position: absolute;
    width: 697.54px;
    height: 204px;
    left: 835px;
    top: 1313.14px;

    /* Hero Heading */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 66px;
    line-height: 68px;
    /* or 103% */

    letter-spacing: -0.075em;

    /* Dodger Blue */

    color: #4da6ff;
`;

export const BuildWrapper = styled.div`
    position: absolute;
    width: 537.37px;
    height: 162px;
    left: 835px;
    top: 1552.14px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    /* or 112% */

    letter-spacing: -0.03em;

    /* Indigo Dye */

    color: #164069;
`;

export const PrincipleWrapper = styled.div`
    position: absolute;
    width: 103px;
    height: 19px;
    left: 835px;
    top: 1274px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height, or 106% */

    letter-spacing: -0.05em;

    /* Indigo Dye */

    color: #164069;
`;

export const SubscriptionImgWrapper = styled.img`
    position: absolute;
    width: 593px;
    height: 742px;
    left: 147px;
    top: 1190.14px;
`;
