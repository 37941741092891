import styled from "styled-components";

/**
 * The Table Center wrapper
 */
export const TableCenter = styled.table`
    margin-left: auto;
    margin-right: auto;
`;

/**
 * The Profile Image wrapper
 */
export const ProfileImg = styled.img`
    width: 80px;
    height: 80px;
    object-fit: cover;

    /* Indigo Dye */
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

/**
 * The Small Video wrapper
 */
export const SmallVideoWrapper = styled.img`
    width: 136px;
    height: 98px;
    flex-shrink: 0;
    object-fit: cover;

    /* Indigo Dye */
    border-radius: 8px;
    border: 2px solid #164069;
    background:
        url(<path-to-image>),
        lightgray 50% / cover no-repeat;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

/**
 * The Small Title wrapper
 */
export const SmallTitleWrapper = styled.p`
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px; /* 118.75% */
    /* identical to box height, or 119% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;

    /* Indigo Dye */

    color: #164069;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`;

/**
 * The Small Subtitle wrapper
 */
export const SmallSubtitleWrapper = styled.div`
    /* Detail (Bold) */
    /* Detail (Bold) */
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: #164069;
    display: flex;
    align-items: center;

    span {
        white-space: pre;

        font-family: "Manrope";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: #164069;
    }

    letter-spacing: -0.03em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`;

/**
 * The Title wrapper
 */
export const TitleWrapper = styled.p`
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 19px;
    /* identical to box height, or 119% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;

    /* Indigo Dye */

    color: #164069;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`;

/**
 * The Subtitle wrapper
 */
export const SubtitleWrapper = styled.p`
    /* Detail */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height, or 133% */

    letter-spacing: -0.03em;

    /* Indigo Dye */

    color: #164069;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`;

/**
 * The Button wrapper
 */
export const ButtonWrapper = styled.button`
    background-color: transparent;
    border-color: transparent;
`;
