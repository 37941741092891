// CSS
import './App.css';

// external
import React from 'react';
import { useSelector } from 'react-redux';

// local
import { Header } from './components/Header';
import { FourthComponent } from './FourthComponent';
// import { Footer } from "./components/Footer";
import { HeroComponent } from './HeroComponent';
import { PlaceComponent } from './PlaceComponent';
// selectors
import { selectSearchValue } from './Search/selectors';
import { SearchTable } from './SearchTable';
import { ThirdComponent } from './ThirdComponent';

const App: React.FC = () => {
    const searchValue = useSelector(selectSearchValue);

    if (searchValue === "") {
        return (
            <div className="App">
                <Header />
                <HeroComponent />
                <PlaceComponent />
                <ThirdComponent />
                <FourthComponent />
            </div>
        );
    }
    return (
        <div className="App">
            <Header />
            <SearchTable />
        </div>
    );
};

export default App;
