// external
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import {
  DynamicContextProvider,
  DynamicWidget,
  getAuthToken
} from "@dynamic-labs/sdk-react-core";

// local
import { selectUserValue } from "../../Auth/selectors";
import { setUser } from "../../Auth/slice";
import { setSearch } from "../../Search/slice";
import Search from "../Search";
import { headerMessages } from "./messages";
import { ModalUploadContainer } from "./ModalUploadContainer";
import { DYNAMIC_SIGN_IN } from "./queries";
import { BlockWrapper, NetboxWrapper, WalletWrapper } from "./wrappers";

export interface StateUser {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  picture: string;
  channelId?: string;
}

/**
 * Header used in the App component
 */
export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mutateSignIn] = useMutation(DYNAMIC_SIGN_IN);
  const userValue = useSelector(selectUserValue);

  useEffect(() => {
    const dynamicAuthToken = getAuthToken();
    if (dynamicAuthToken) {
      // If the user is already signed in, we can fetch the user data
      mutateSignIn({
        context: {
          headers: {
            authorization: dynamicAuthToken
          }
        }
      }).then(({ data }) => {
        const dynamicUser = data?.dynamicSignIn;
        const newUser = {
          id: dynamicUser?.id,
          email: dynamicUser?.email,
          firstName: dynamicUser?.firstName,
          lastName: dynamicUser?.lastName,
          username: dynamicUser?.username,
          picture: dynamicUser?.picture,
          channelId: dynamicUser?.channelId
        };
        dispatch(setUser(newUser));
      });
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const dynamicWidget = (
    <WalletWrapper>
      <DynamicWidget
        innerButtonComponent={
          <button
            style={{
              fontFamily: "Manrope",
              fontStyle: "normal"
            }}
          >
            {headerMessages.signIn}
          </button>
        }
      />
    </WalletWrapper>
  );

  return (
    <DynamicContextProvider
      settings={{
        // Find your environment id at https://app.dynamic.xyz/dashboard/developer
        environmentId: "2b9b5582-b018-4d3e-aaed-880bfd33ee18",
        walletConnectors: [EthereumWalletConnectors],
        eventsCallbacks: {
          onAuthSuccess: async (args) => {
            const { data } = await mutateSignIn({
              context: {
                headers: {
                  authorization: args.authToken
                }
              }
            });
            const dynamicUser = data?.dynamicSignIn;
            const newUser = {
              id: dynamicUser?.id,
              email: dynamicUser?.email,
              firstName: dynamicUser?.firstName,
              lastName: dynamicUser?.lastName,
              username: dynamicUser?.username,
              picture: dynamicUser?.picture,
              channelId: dynamicUser?.channelId
            };

            dispatch(setUser(newUser));
          },
          onLogout: () => {
            dispatch(setUser({}));
          }
        }
      }}
    >
      <BlockWrapper className="Header">
        <Link to="/" onClick={() => dispatch(setSearch(""))}>
          <NetboxWrapper>{headerMessages.netbox}</NetboxWrapper>
        </Link>
        <Search
          placeholder={"Search"}
          value={""}
          onChange={(e) => {
            dispatch(setSearch(e));
          }}
        />
        <ModalUploadContainer user={userValue} />
        {dynamicWidget}
      </BlockWrapper>
    </DynamicContextProvider>
  );
};
