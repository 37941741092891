import { UserState } from "./slice";
import { createSelector } from "reselect";

/**
 * The selector for the `Privacies` state store slice
 */
export const getUserState = (state: any): UserState => state.user;

/**
 * The privacy center policies that contains all local changes
 */
export const selectUserValue = createSelector(getUserState, (user) => user);
