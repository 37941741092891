// external
import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import { BottomTitle } from '../BottomTitle';
import { StateVideo } from '../types';
import { BottomLine, UploadModalWrapper } from './wrappers';

interface FooterProps {
    /** The current state of the footer */
    state: StateVideo;
    /** Setting the state index */
    setState: (state: StateVideo) => void;
    /** Setting the state index */
    onNext: () => void;
}

export function Footer({ state, setState, onNext }: FooterProps) {
    const nextTitle = state.index === 3 ? "Upload" : "Next";
    const progress = state?.progress;

    const updateState = (index: number) => {
        setState({
            index,
            title: state.title,
            description: state?.description,
            videoUrl: state?.videoUrl,
            thumbnailUrl: state?.thumbnailUrl,
            videoId: state?.videoId,
            audience: state.audience,
            emailPreference: state?.emailPreference,
            progress: state?.progress
        });
    };

    return (
        <>
            <BottomLine src="/Line.png" />
            <BottomTitle
                index={1}
                title={"Details"}
                isCurrent={state.index === 0}
                onClick={() => updateState(1)}
            />
            <BottomTitle
                index={2}
                title={"Audience"}
                isCurrent={state.index === 2}
                onClick={() => updateState(2)}
            />
            <BottomTitle
                index={3}
                title={"Review"}
                isCurrent={state.index === 3}
                onClick={() => updateState(3)}
            />
            {progress && progress > 0 && progress < 100 && (
                <ProgressBar
                    now={progress}
                    label={`${Math.round(progress)}%`}
                    style={{ height: "38px", width: "300px" }}
                />
            )}
            <UploadModalWrapper onClick={onNext}>
                {nextTitle}
            </UploadModalWrapper>
        </>
    );
}
