// external
import styled from "styled-components";

/**
 * The Player wrapper
 */
export const PlayerWrapper = styled.div`
    box-sizing: border-box;

    position: absolute;
    width: 1200px;
    height: 675px;
    left: 18px;
    top: 98px;
`;

/**
 * The Information Wrapper
 */
export const DescriptionWrapper = styled.div`
    padding: 8px 16px;
    gap: 3px;

    position: absolute;
    width: 412px;
    height: 55px;
    left: 1251px;

    /* Alice Blue */

    background: #e6f2fe;
    /* Grit Blue (15% Opacity) */
    border: 1px solid rgba(1, 13, 25, 0.15);
    border-radius: 4px;
`;

export const InfoWrapper = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 13px;
    gap: 8px;

    height: 40px;

    /* Bay Blue */
    background: ${(props) => (props.selected ? "#c3e0fd" : "transparent")};
    color: ${(props) => (props.selected ? "#164069" : "#4DA6FF")};
    border-radius: 3px;
    border-color: transparent;
`;

export const InfoImgWrapper = styled.img`
    width: 24px;
    height: 24px;
`;

export const TranscriptWrapper = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 18px;
    gap: 8px;

    height: 40px;

    background: ${(props) => (props.selected ? "#c3e0fd" : "transparent")};
    color: ${(props) => (props.selected ? "#164069" : "#4DA6FF")};
    border-radius: 8px;
    border-color: transparent;
`;

export const RelatedWrapper = styled.div`
    position: absolute;
    grid-template-columns: 1fr 1fr;
    top: 791px;
    left: 18px;
    width: 100%;
`;

export const RelatedVideos = styled.div`
    position: absolute;
    top: 60px;
`;

export const RelatedText = styled.div`
    /* Related Videos */
    width: 134px;
    height: 23px;
    position: absolute;
    top: 10px;
    left: 4px;

    /* Heading 3 */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height, or 115% */

    letter-spacing: -0.04em;

    /* Indigo Dye */
    color: #164069;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;
