// external
import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import { StateVideo } from '../types';
import { videoDescriptionMessages } from './messages';
import { GET_VIDEO_INFO } from './queries';
import {
    DescriptionWrapper, DetailsWrapper, ImgThumbnailWrapper, LoadingWrapper, TitleWrapper
} from './wrappers';

interface VideoProps {
    state: StateVideo;
}

export function VideoDescriptionThumbnail({ state }: VideoProps) {
    const { data, error } = useQuery(GET_VIDEO_INFO, {
        variables: { video_id: state?.videoId || "1" }
    });
    const video = data?.video;
    const title = state?.title || video?.title;
    const thumbnailUrl = state?.thumbnailUrl || video?.thumbnailUrl;
    const videoUrl = state?.videoUrl || video?.videoUrl;
    return (
        <DetailsWrapper>
            {thumbnailUrl ? (
                <ImgThumbnailWrapper src={thumbnailUrl} />
            ) : (
                <LoadingWrapper>
                    {videoDescriptionMessages.uploading}
                </LoadingWrapper>
            )}
            <DescriptionWrapper>
                {thumbnailUrl
                    ? videoDescriptionMessages.videoUploaded
                    : videoDescriptionMessages.videoUploading}
            </DescriptionWrapper>
            <TitleWrapper>{videoUrl}</TitleWrapper>
            <DescriptionWrapper>
                {videoDescriptionMessages.filename}
            </DescriptionWrapper>
            <TitleWrapper>{title}</TitleWrapper>
        </DetailsWrapper>
    );
}
