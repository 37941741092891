// external
import styled from "styled-components";

/**
 * The Profile image wrapper
 */
export const ProfileWrapper = styled.img`
    position: absolute;
    width: 200px;
    height: 200px;
    left: 111px;
    top: 161px;
    object-fit: fill;

    border-radius: 100px 100px 100px 100px;
`;

/**
 * The Subscriber wrapper
 */
export const SubscriberWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 546px;
    left: 0px;
    top: 80px;

    background: #f7f7f7;
`;

/**
 * The Title Input wrapper
 */
export const TitleInputWrapper = styled.input`
    position: absolute;
    width: 537px;
    height: 37px;
    left: 414px;
    top: 169px;
`;

/**
 * The Subtitle Input wrapper
 */
export const SubtitleInputWrapper = styled.input`
    position: absolute;
    width: 537px;
    height: 87px;
    left: 414px;
    top: 224px;
    text-align: top;
    vertical-align: top;
`;

/**
 * The Monthly wrapper
 */
export const MonthlyWrapper = styled.div`
    position: absolute;
    width: 124px;
    height: 26px;
    left: 414px;
    top: 333px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    color: #000000;
`;

/**
 * The Monthly input
 */
export const MonthlyInput = styled.input`
    position: absolute;
    width: 191px;
    height: 37px;
    left: 414px;
    top: 377px;
`;

/**
 * The Annual wrapper
 */
export const AnnualWrapper = styled.div`
    position: absolute;
    width: 124px;
    height: 26px;
    left: 414px;
    top: 436px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    color: #000000;
`;

/**
 * The Annual input
 */
export const AnnualInput = styled.input`
    position: absolute;
    width: 191px;
    height: 37px;
    left: 414px;
    top: 474px;
`;

/**
 * The Founding wrapper
 */
export const FoundingWrapper = styled.div`
    position: absolute;
    width: 206px;
    height: 26px;
    left: 414px;
    top: 529px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    color: #000000;
`;

/**
 * The Founding input
 */
export const FoundingInput = styled.input`
    position: absolute;
    width: 191px;
    height: 37px;
    left: 414px;
    top: 567px;
`;

/**
 * The Save Button Wrapper
 */
export const SaveButtonWrapper = styled.button`
    position: absolute;
    width: 234px;
    height: 54px;
    left: 1101px;
    top: 169px;

    border: none;
    background: #469df8;
    border-radius: 6px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #ffffff;
`;

/**
 * The Cancel Button Wrapper
 */
export const CancelButtonWrapper = styled.button`
    position: absolute;
    width: 234px;
    height: 54px;
    left: 1101px;
    top: 236px;

    border: 1px solid #b2b4c0;
    background: #ffffff;
    border-radius: 6px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: #b2b4c0;
`;

/**
 * The Uploads Title wrapper
 */
export const UploadsTitleWrapper = styled.div`
    position: absolute;
    width: 384px;
    height: 30px;
    left: 132px;
    top: 690px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;

    color: #000000;
`;

/**
 * The Video Preview wrapper
 */
export const VideoPreviewWrapper = styled.div`
    position: absolute;
    top: 750px;
`;

/**
 * The Second video wrapper
 */
export const SecondVideoPreviewWrapper = styled.div`
    position: absolute;
    top: 750px;
    left: 450px;
`;

/**
 * The Second video wrapper
 */
export const ThirdVideoPreviewWrapper = styled.div`
    position: absolute;
    top: 750px;
    left: 900px;
`;
