import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * The User state
 */
export interface UserState {
    /** Email for user. */
    email?: string;
    /** First name */
    firstName?: string;
    /** Last name */
    lastName?: string;
    /** Username */
    username?: string;
    /** Picture */
    picture?: string;
    /** User ID */
    id?: string;
    /** Channel ID */
    channelId?: string;
}

export const userSlice = createSlice({
    name: "user",
    initialState: {} as UserState,
    reducers: {
        setUser: (state, { payload }: PayloadAction<UserState>) => ({
            email: payload.email,
            firstName: payload.firstName,
            lastName: payload.lastName,
            username: payload.username,
            picture: payload.picture,
            id: payload.id,
            channelId: payload.channelId
        })
    }
});

export const { setUser } = userSlice.actions;
