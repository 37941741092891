import 'video-react/dist/video-react.css'; // import css

// external
import React from 'react';
import { BigPlayButton, Player } from 'video-react';

// local
import { heroMessages } from './messages';
import {
    BackgroundWrapper, CenterWrapper, ContentWrapper, CreateButtonWrapper, CTAButtonWrapper,
    NetboxImgWrapper, PlayerWrapper, SecondImgWrapper, StartTextWrapper, TextWrapper,
    ThirdImgWrapper, VideosButtonWrapper, VideosTextWrapper
} from './wrappers';

export const HeroComponent = () => {
    return (
        <BackgroundWrapper>
            <CTAButtonWrapper>
                <TextWrapper>{heroMessages.netboxProtocol}</TextWrapper>
            </CTAButtonWrapper>
            <NetboxImgWrapper src="/background.png"></NetboxImgWrapper>
            <CenterWrapper>{heroMessages.economicPlatform}</CenterWrapper>
            <SecondImgWrapper src="/sheldon.jpg"></SecondImgWrapper>
            <ThirdImgWrapper src="/graham.jpg"></ThirdImgWrapper>
            <PlayerWrapper>
                <Player
                    width={800}
                    fluid={false}
                    src="https://netboxvideos.s3.us-east-2.amazonaws.com/videos/bull.mp4"
                    poster="https://netboxvideos.s3.us-east-2.amazonaws.com/images/bull.jpg"
                >
                    <BigPlayButton position="center" />
                </Player>
            </PlayerWrapper>
            <ContentWrapper>{heroMessages.growAudience}</ContentWrapper>
            <CreateButtonWrapper>
                <StartTextWrapper>{heroMessages.signUp}</StartTextWrapper>
            </CreateButtonWrapper>
            <VideosButtonWrapper>
                <VideosTextWrapper>
                    {heroMessages.watchVideos}
                </VideosTextWrapper>
            </VideosButtonWrapper>
        </BackgroundWrapper>
    );
};
