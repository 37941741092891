import styled from 'styled-components';

/**
 * The Upload Modal Wrapper Button
 */
export const UploadModalWrapper = styled.button`
    display: flex;
    width: 200px;
    height: 60px;
    font-weight: bold;
    padding: 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--bay-blue, #c3e0fd);
    border: none;
`;

export const BottomLine = styled.img`
    position: absolute;
    left: 50px;
`;
