// local
import {
    DescriptionWrapper,
    HeroImgWrapper,
    SubtitleWrapper,
    ThirdWrapper,
    TitleWrapper
} from "./wrappers";

// libraries
import React from "react";

export function ThirdComponent() {
    return (
        <>
            <ThirdWrapper />
            <TitleWrapper>The Idea</TitleWrapper>
            <SubtitleWrapper>
                You are in charge, and you own your audience
            </SubtitleWrapper>
            <DescriptionWrapper>
                Build a community that you own that your fans will love and
                create a subscription plan to make money that makes it worth it.
                <br />
                <br />
                Publishing is free, with no limits. We only make money when you
                do.
            </DescriptionWrapper>
            <HeroImgWrapper src="/render.png" />
        </>
    );
}
