// external
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Footer } from '../Footer';
import { StateVideo } from '../types';
import { VideoDescriptionThumbnail } from '../VideoDescriptionThumbnail';
import { publishMessages } from './messages';
import {
    ContainerSubtitle, ContainerTitle, ContainerWrapper, ImgMarkedWrapper, SubtitleWrapper,
    TitleWrapper, TopContainer, UnselectedWrapper
} from './wrappers';

type Audience = "Public" | "Private";

interface ContainerProps {
    title: string;
    subtitle: string;
    selected: boolean;
    audience: Audience;
    setAudience: (audience: Audience) => void;
}

const ContainerBox = ({
    title,
    subtitle,
    selected,
    audience,
    setAudience
}: ContainerProps) => {
    const imageSrc = selected ? "/marked.png" : "/unmarked.png";
    const Wrapper = selected ? ContainerWrapper : UnselectedWrapper;

    return (
        <Wrapper onClick={() => setAudience(audience)}>
            <ImgMarkedWrapper src={imageSrc} />
            <div>
                <ContainerTitle>{title}</ContainerTitle>
                <ContainerSubtitle>{subtitle}</ContainerSubtitle>
            </div>
        </Wrapper>
    );
};

interface AudienceProps {
    /** Setting the state index */
    setState: (state: StateVideo) => void;
    /** The current state */
    state: StateVideo;
}

export function Audience({ setState, state }: AudienceProps) {
    const title = state.title;
    const [show, setShow] = useState(false);
    const initialAudience: Audience =
        state.audience === "Private" ? "Private" : "Public";
    const [audience, setAudience] = useState<Audience>(initialAudience);

    const onNext = () => {
        setState({
            index: 3,
            audience,
            title: state.title,
            description: state?.description,
            videoUrl: state?.videoUrl,
            thumbnailUrl: state?.thumbnailUrl,
            videoId: state?.videoId,
            progress: state?.progress
        });
        setShow(false);
    };

    useEffect(() => {
        setState({
            index: state.index,
            audience,
            title: state.title,
            description: state?.description,
            videoUrl: state?.videoUrl,
            thumbnailUrl: state?.thumbnailUrl,
            videoId: state?.videoId,
            emailPreference: state?.emailPreference,
            progress: state?.progress
        });
    }, [audience]);

    const indexState = {
        index: 2,
        audience,
        title: state.title,
        description: state?.description,
        videoUrl: state?.videoUrl,
        thumbnailUrl: state?.thumbnailUrl,
        videoId: state?.videoId,
        emailPreference: state?.emailPreference,
        progress: state?.progress
    };

    const handleAudienceChange = (newAudience: Audience) => {
        setAudience(newAudience);
    };
    return (
        <div>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TopContainer>
                    <div>
                        <TitleWrapper>{publishMessages.audience}</TitleWrapper>
                        <SubtitleWrapper>
                            {publishMessages.chooseAudience}
                        </SubtitleWrapper>
                        <ContainerBox
                            title={publishMessages.saveAndPublish}
                            subtitle={publishMessages.publicDescription}
                            selected={audience === "Public"}
                            audience="Public"
                            setAudience={handleAudienceChange}
                        />
                        <ContainerBox
                            title={publishMessages.saveAndPrivate}
                            subtitle={publishMessages.onlySubscribers}
                            selected={audience === "Private"}
                            audience="Private"
                            setAudience={handleAudienceChange}
                        />
                    </div>
                    <VideoDescriptionThumbnail state={state} />
                </TopContainer>
            </Modal.Body>
            <Modal.Footer>
                <Footer
                    state={indexState}
                    onNext={onNext}
                    setState={setState}
                />
            </Modal.Footer>
        </div>
    );
}
