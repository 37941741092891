import styled from "styled-components";

/**
 * The Video Image Wrapper
 */
export const VideoImgWrapper = styled.img`
    position: absolute;
    width: 348px;
    height: 196px;
    left: 132px;
    object-fit: fill;
`;

/**
 * The Title Wrapper
 */
export const TitleWrapper = styled.div`
    position: absolute;
    width: 195px;
    height: 26px;
    left: 132px;
    top: 220px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    color: #000000;
`;

/**
 * The Subtitle Wrapper
 */
export const SubtitleWrapper = styled.div`
    position: absolute;
    width: 95px;
    height: 21px;
    left: 381px;
    top: 224px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #000000;
`;
