import styled from "styled-components";

/**
 * The Block wrapper
 */
export const BlockWrapper = styled.div`
    padding: 20px;
    height: 100px;
`;

export const WalletWrapper = styled.div`
    position: absolute;
    left: 1450px;
    top: 26px;
    width: 160px;
`;

/**
 * The NETBOX wrapper
 */
export const NetboxWrapper = styled.button`
    position: absolute;
    left: 18px;
    top: 32px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 16px;

    text-align: left;
    color: #469df8;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 0px;
    background: url("/Logo.png") no-repeat top left;
`;

export const UserButtonWrapper = styled.button`
    position: absolute;
    left: 1450px;
    top: 28px;
    width: 24px;
    height: 24px;
    border-color: transparent;
    background-color: transparent;
`;

/**
 * The User Image wrapper
 */
export const UserImgWrapper = styled.img`
    box-sizing: border-box;

    width: 24px;
    height: 24px;
    border: 1px solid #164069;
    border-radius: 12px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
`;

/**
 * The User wrapper
 */
export const LoginWrapper = styled.button`
    position: absolute;
    left: 1450px;
    top: 26px;
    width: 86px;
    height: 29px;
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;

    /* Dodger Blue */
    border: 1px solid #4da6ff;
    border-radius: 8px;
    background-color: #ffffff;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    /* Body (Bold) */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 119% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Dodger Blue */
    color: #4da6ff;
`;

/**
 * The Logout Wrapper
 */
export const LogoutWrapper = styled.button`
    position: absolute;
    width: 86px;
    height: 30px;
    left: 25px;
    top: 25px;

    /* Dodger Blue */
    border: 1px solid #4da6ff;
    border-radius: 8px;
    background-color: #ffffff;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    /* Body (Bold) */
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;

    text-align: center;

    /* Dodger Blue */
    color: #4da6ff;
`;

export const LoginImage = styled.img`
    /* Frame */
    width: 16px;
    height: 16px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
`;

export const PopoverWrapper = styled.div`
    /* Auto layout */
    background-color: #ffffff;
    border: 1px solid #e5e5e5;

    width: 140px;
    height: 80px;
`;
