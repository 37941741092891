// local
import { SubtitleWrapper, TitleWrapper, VideoImgWrapper } from "./wrappers";

// libraries
import React from "react";

/*
 * Video Thumbnail preview that can be clicked
 */
export function VideoPreview() {
    return (
        <React.Fragment>
            <VideoImgWrapper src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" />
            <TitleWrapper>Pomp how to invest</TitleWrapper>
            <SubtitleWrapper>9 hours ago</SubtitleWrapper>
        </React.Fragment>
    );
}
